/* Styling for the modal container */
#modalContainer {
    display: none; /* Hide the modal by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent background using rgba */
    z-index: 9999;
}

#modalContent {
    overflow: auto;
}

#imageContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styling for the modal content */
#modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-height: 80%;

}

/* Styling for the close button */
#closeModalBtn {
    margin-top: 10px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.img-thumb {
    width: 100% !important;
    min-height: 300px !important;
    max-height: 300px !important;
}

#preview-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
}

#preview-image {
    display: block;
    max-width: 90%;
    max-height: 90%;
    margin: auto;
    margin-top: 50px;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
}

.thumbnail {
    width: 200px; /* Thumbnail width */
    height: 200px; /* Thumbnail height */
    background-size: cover; /* Resize the background image to cover the container */
    background-repeat: no-repeat; /* Prevent repeating the background image */
    background-position: center; /* Center the background image */
}