.active#pills-home-tab {
    background-color: #b5a26b !important;
    color: white !important;
    font-size: 16px !important;
}

.active#pills-profile-tab {
    background-color: #b5a26b !important;
    color: white !important;
    font-size: 16px !important;
}

.active#pills-contact-tab {
    background-color: #b5a26b !important;
    color: white !important;
    font-size: 16px !important;
}